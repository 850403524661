import React from "react";
import Modal from "../../../components/Modals";
import moment from "moment";

const ModalDriverFreightCheckpoints = ({ actionModal, openModal, selectedEntity }) => {
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-12/12 lg:w-6/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 className={`mr-6 text-roxo_oficial font-bold`}>Checkpoints</h5>
                </div>
            }
        >
            <>
                {selectedEntity?.checkpoints?.length > 0 ? (
                    <table className="table-auto mt-10 w-full text-left">
                        <thead className="border-b border-roxo_oficial">
                            <tr>
                                <th>Motorista</th>
                                <th>Veículo</th>
                                <th>Velocidade média</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedEntity?.checkpoints.map(checkpoint => (
                                <tr>
                                    <td>{checkpoint.userDriverName}</td>
                                    <td>{checkpoint.vehicle.licensePlate}</td>
                                    <td>{checkpoint.speed} km/h</td>
                                    <td>{checkpoint.latitude}</td>
                                    <td>{checkpoint.longitude}</td>
                                    <td>{moment(checkpoint.receivedAt).format("DD/MM/YYYY HH:mm:ss")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Viagem sem checkpoints</p>
                )}
            </>
        </Modal>
    );
};

export default ModalDriverFreightCheckpoints;
